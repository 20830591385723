<template>
  <div class="flex flex-col container mx-auto justify-center items-center space-y-6">
    <p class="text-center text-2xl">
      {{ $t('FileGenerating') }}
    </p>

    <div
      style="height: 100px; width: 100px;"
      class="flex items-center justify-center p-[2rem] rounded-full border-[10px] border-[#00579c]">
      <p class="font-bold text-[3rem]">{{ seconds }}</p>
    </div>
    <button @click="fetchDownloadLink()" class="bg-[#00579c] text-white font-medium px-6 py-2 rounded-lg">
      {{ $t("Check") }}
    </button>
  </div>
</template>

<script>
import CountDown from 'keep-countdown'
import 'keep-countdown/dist/lib/index.css'

export default {
  name: "DownloadFilePage",
  components: { CountDown },
  data() {
    return {
      seconds: 20,
      timer: null,
    }
  },
  props: {
    downloadId: {
      type: String,
    },
  },
  methods: {
    onEnded() {
      console.log("ended");
    },
    fetchDownloadLink() {
      this.$vs.loading()
      return this.$store.dispatch('reports/fetchDownloadLink', {
        download_id: this.downloadId,
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          if(data.url) {
            window.open(`https://${window.location.host}${data.url}`, "_blank")
            // console.log(`https://${window.location.host}${data.url}`)
            // const hiddenElement = document.createElement('a')
            // hiddenElement.href = `https://${window.location.host}${data.url}`
            // hiddenElement.target = '_blank'
            // hiddenElement.download = 'sales_report.xlsx';
            // hiddenElement.click()
          } else {
            this.init();
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    init() {
      this.seconds = 20;
      try {
        clearInterval(this.timer);
      } catch (e) {

      }
      this.timer = setInterval(() => {
        this.seconds -= 1;
        if(this.seconds === 0) {
          this.fetchDownloadLink();
          clearInterval(this.timer);
        }
      }, 1000)
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>

</style>
